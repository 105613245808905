import React from 'react';
import {
  Box,
  Container, 
  makeStyles
} from '@material-ui/core'; 
import Page from 'src/components/Page';
import ListInvoices from './ListInvoice'; 
 
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  } 
}));

const CustInvoiceList = (props) => {
  const classes = useStyles();
   return (
     <Page
       className={classes.root}
       title="Vendor"
     >
       <Container maxWidth={false}>
         <Box mt={3}>
           <ListInvoices {...props} />
         </Box>
       </Container>
     </Page>
  );
};

export default CustInvoiceList;
