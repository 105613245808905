import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles, 
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Paper,
  Divider,
  Card,
  CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
import Amplify, { Auth, API } from 'aws-amplify';
import config from 'src/aws-exports';
import { useSnackbar } from 'notistack';
import Spinner from 'src/common/Spinner';

Amplify.configure(config);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },  
  header: {
    fontSize: 20,
    fontStyle: "bold",
    fontFamily: "Arial-BoldMT, Arial"
  },
  myImage: {
    width: "auto",
    height:"103px"
  },
  margin: {
    marginTop: theme.spacing(2),
  },  
  typography: {
    align: "center",
    textAlign: 'center',
    marginTop: theme.spacing(7),
    flexGrow: 1,
  }
}));

const Thankyou = () => {
  const classes = useStyles();  
  return (
    <Page
      className={classes.root}
      title='Thank You'
    > 
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'
      >
        <Container maxWidth='lg'>
                  <div className={classes.paper}>
                      <Grid container justify="center" alignItems="center" spacing={1}>
                          <Grid item>
                             
                          </Grid>
                          <Grid item>
                              <Typography component="h1" variant="h1" className={classes.typography}>
                                Thank You
                              </Typography>
                          </Grid>
                      </Grid>
                  </div>
         </Container>
      </Box>
    </Page>
  );
};

export default Thankyou;
