import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather'; 
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  exportDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }, 
  exportSpinner: {
    color: "#909199",
    marginRight: "8px"
  },
  exportTextDisabled: {
    color: "#909199",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px"
  },  
  exportTextEnabled: {
    color: "#000000"
  }
})); 
const Toolbar = ({ className, handleSearch,userSession,exportOnClick,isLoading, ...rest }) => { 
  const classes = useStyles();  
  
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >  
      <Box
        display="flex"
        justifyContent="flex-end"
      >  
        <div className={isLoading ? classes.exportTextDisabled: classes.exportTextEnabled}>
        {isLoading && <CircularProgress  size={20} className={classes.exportSpinner}/>}         
        <Button disabled={isLoading}  className={classes.exportButton} onClick={exportOnClick}>
          Export
        </Button>
        </div>        
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search vendor"
                variant="outlined"
                id="searchItem"       
                onBlur={handleSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleSearch:PropTypes.func,
  userSession:PropTypes.object,
  exportOnClick:PropTypes.func, 
  isLoading:PropTypes.bool
};

export default Toolbar;
