import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types'; 
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu'; 
import MenuIcon from '@material-ui/icons/MoreVert';  
import {
  Box,
  Card,
  CardHeader, 
  Divider, 
  makeStyles 
} from '@material-ui/core';
import MaterialTable from "material-table";
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";  
  
const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  tooltipText: {
    wordWrap:"nowrap",
    float:"left",
    paddingRight: "9px",
    verticalAlign: "middle",
    display: "inline-flex",
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.78)",
    fontSize: "0.875rem"
    },
    toolbarCss:{
      'margin-bottom':'15px'
    }
}));

const ListAffidavit = ({ userSession, className, filterChange,queryChange, ...rest }) => {  
  const classes = useStyles();   
  const [pageSize, setPageSize] = usePersistedState('pageSize', 15); 
  const [currentRow, setCurrentRow] = useState({}); 
  const [menuAnchor, setMenuAnchor] = useState(null); 
  const [menuOpen, setMenuOpen] = useState(false);   
  const tableRef = React.createRef(); 

  React.useEffect(() => {
    console.log('filterChange', filterChange);
    if (tableRef.current) tableRef.current.onQueryChange({ page: 0, Filter: filterChange })
  }, [filterChange]);

  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 

  }
  async function  viewJson() {
    var row = currentRow;  
 }
  async function GetPagingData(query) { 
    queryChange(query);
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
    let body = {}; 
    setPageSize(pageSize);
    body = {
      type: 'Affidavit',
      tenantId:userSession.TenantId,
      UserID: userSession.UserName, 
      pageSize: pageSize,
      pageNo: pageNo,
      filter: filterChange
    };
    return new Promise((resolve, reject) => {
      let apiName = "BaseAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: "affidavit",
          op: "getAffidavit"
        }
      };
      API.post(apiName, path, myInit).then(response => { 
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true);
  } 
 async function handleMenuClose(){
  await setMenuOpen(false);
 }
 async function downloadInvoice(type) { 
     let body = {}; 
    let apiName = "BaseAPI";
    let path = "/module";
    let masterType = "Signed Invoice";
    body = {
      type: type,  
      pk:currentRow.pk,   
      UserID: userSession.UserName, 
      tenantId:userSession.TenantId      
    };
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body:body,
      queryStringParameters: {
        module:"invoice",
        op: "downloadInvoice"
      }
    };
    await API.post(apiName, path, myInit).then(response => { 
      if(response.success){
      const link = document.createElement('a');
      link.href = response.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
      
    }).catch(err => {
      console.log(err);
    }); 
  }  
  return ( 
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Affidavit" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MaterialTable
            tableRef={tableRef}
            columns={[
              {
                title: 'Vendor Name',
                field: 'OrganizationName',
                searchable: false,
                width:"10%"
              },
              {
                title: 'Pan',
                field: 'Pan',
                searchable: false ,
                width:"10%"
              },
              {
                title: 'Return Status Latest (Yes/No)',
                field: 'Itr_20_21_Status',
                searchable: false,
                width:"5%"
              },
              {
                title: 'Return Status Previous (Yes/No)',
                field: 'Itr_19_20_Status',
                searchable: false,
                width:"5%"
              }
            ]}           
            data={async query =>
              new Promise((resolve, reject) => {
                GetPagingData(query).then(result => {
                  resolve({
                    data: result.data,
                    page: result.page,
                    totalCount: result.total
                  });
                });
              })
            } 
            options={{
              searchFieldStyle: {
                display: 'none'
              }, 
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              toolbar: false,
              pageSize: pageSize,
              pageSizeOptions: [5, 25, 50, 100, 200]
            }}
            actions={[
              {
                icon: ()=> <MenuIcon/>,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, row) => {
                  openMenu(event, row);
                }
              }
           ]}
          />         
        </Box>
      </PerfectScrollbar>
      <Menu
            id="simple-menu"
            keepMounted
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={viewJson}>View</MenuItem>
            <MenuItem onClick={()=>downloadInvoice("json")}>
              Download
            </MenuItem> 
          </Menu>
    </Card>
   );
};

ListAffidavit.propTypes = {
  className: PropTypes.string,
  filterChange: PropTypes.string, 
  userSession: PropTypes.object,
  queryChange: PropTypes.func
};

export default ListAffidavit;
