import React from "react";
import { API } from 'aws-amplify';  
    
   const verifyUser=async props =>{
    return new Promise((resolve, reject) => {
      let apiName = "BaseAPI";
      let path = "/verify/verify";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: props.userData 
      };
      API.post(apiName, path, myInit).then(response => {        
        console.log("user response",response);              
        resolve(response);
      }).catch(err=>{console.log(err); reject(err);
      });
    });
   }


  export default class DataFetcher extends React.Component {
    constructor(props) {
      console.log("Fetcher before",props);  
      super(props);
      this.state = {
        data: null,
        IsLoading: false,
        error: null,
      };
     console.log("Fetcher after",props);    
    }
    async componentDidUpdate(nextProps) {
      console.log("getMaster componentDidMount",nextProps);
      console.log("this.props.type",nextProps.type);
      if(nextProps.render){
        this.setState({ IsLoading: true });
        if(nextProps.type==="verify")
        {
          console.log("verifyUser componentDidMount",nextProps.userData);
          await verifyUser({...nextProps})
          .then(result => this.setState({
            data: result,
            IsLoading: false
          }))
          .catch(error => this.setState({
            error,
            IsLoading: false
          }));
        } 
      }
    }  
    async componentDidMount() {
      console.log("getMaster componentDidMount 2",this.props);
      console.log("this.props.type",this.props.type);
      
      this.setState({ IsLoading: true });
     if(this.props.type==="verify")
      {
        console.log("verifyUser componentDidMount",this.props.userData);
        await verifyUser({...this.props})
        .then(result => this.setState({
          data: result,
          IsLoading: false
        }))
        .catch(error => this.setState({
          error,
          IsLoading: false
        }));
      } 
    } 
    render() {  
      console.log("Fetcher render",this.props.children);
      console.log(" this.state ", this.state );
      return (this.props.children(this.state));
    }
  }
 
