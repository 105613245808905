import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';  
import SurveyForm from "./SurveyForm";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

 
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  } 
}));

const AffidavitForm = (props) => {
  const classes = useStyles(); 
  return (
    <Page
    className={classes.root}
    title="Customers"
  >
    <Container maxWidth={false}>      
      <Box mt={3}>      
      <SurveyForm {...props}/>        
      </Box>
    </Container>
  </Page> 
  );
};
 
export default AffidavitForm;
