import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'; 
import PersonIcon from '@material-ui/icons/Person';
import Amplify, { API } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import awsconfig from 'src/aws-exports';
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab"; 

 import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
Amplify.configure(awsconfig);
const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },input: {
    display: 'none',
  },center:{
    "text-align":"center"
  },borderradious:{
    "border-radius": "5px !important"
  }
}));

const Profile = (props,{className, ...rest}) => {
  const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    m: 1,
    border: 1,
    style: { width: '18rem', height: '2rem' },
  };
  const classes = useStyles();  
 const [userDetails, setUserDetails] = useState({
  FirstName:"",
  LastName:"",
  Email:"",
  PhoneNumber:"",
  GSTIN:"",
  CompanyName:"",
  profilePicture:{}
}); 
useEffect(() => {
  setUserDetails(props.userDetails);
}, [props]); 

   return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          
          <Avatar
            className={classes.avatar} 
          >    
          { userDetails.profilePicture ? 
          <S3Image theme={{ photoImg: { maxWidth:  '159px' , maxHeight:  '159px' , display: "flex", marginRight: "auto", padding: "14px" } }} level="public" imgKey={userDetails.profilePicture} />
           : 
           <PersonIcon/> 
          }             

          </Avatar>
          <Typography
            color="textPrimary"
            component={'span'}
            gutterBottom
            variant="h3"
          >
            {userDetails.Name}
          </Typography>
          <Typography
            color="textSecondary"
            component={'span'}
            variant="body1"
          >
            { userDetails.CompanyName}
          </Typography>
          <Typography
            className={classes.dateText}
            component={'span'}
            color="textSecondary"
            variant="body1"
          >
            {userDetails.GSTIN}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      
        <CardActions>
        <Grid item xs={12} className={classes.center}>
                            <input name='profilePicture'  onChange={props.onChange.bind(this)}   accept="image/*" className={classes.input} id="icon-button-file1" type="file" />
                            <label htmlFor="icon-button-file1">
                            <Fab  {...defaultProps} className={classes.borderradious} variant="extended" color="primary" aria-label="upload picture" component="span">
                            UPLOAD PICTURE
                              </Fab>
                            </label>
        </Grid> 
                          
        
      </CardActions>
     
    </Card>

  );
};

Profile.propTypes = {
  className: PropTypes.string,
  onChange:PropTypes.func
};

export default Profile;
