import React, { useState } from 'react';
import {
  withStyles,
  Dialog,
  IconButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  Button,
  Fab,
  Box,
  Typography,
} from '@material-ui/core';
import { API, Storage } from 'aws-amplify';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiCardActions from '@material-ui/core/CardActions';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { useSnackbar } from 'notistack';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import usePersistedState from 'src/utils/usePersistedState';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  avatar: {
    height: 159,
    width: 159,
    backgroundColor: '#bdbdbd24'
  },
  avatarBg: {
    backgroundColor: '#fff'
  }
  , input: {
    display: 'none',
  }, center: {
    'text-align': 'center'
  },
  borderRadious: {
    'border-radius': '5px !important'
  },
  card1: {
    display: "flex",
    alignItems: 'center',
    marginRight: "auto",
    maxHeight: "215px",
    boxShadow: "none",
  },
  details: {
    display: "flex"
  },
  input: {
    display: 'none',
  },
  cardBottomPadding: {
    '&:last-child': {
      paddingBottom: 0
    }
  }
}));
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const CardActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex'
  },
}))(MuiCardActions);
const ImportVendor = ({ userSession, importOpen, manageModal, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = useState({
    ImportKey: '',
    ImportFileName: ''
  });
  const [currentUserDetails] = usePersistedState('currentUserDetails', {});
  React.useEffect(() => {
    setOpen(importOpen);
  }, [importOpen]);
  const onChange = (e) => {
    if (e.target.name === 'ImportKey') {
      const target = e.target;
      const file = target.files[0];
      if (!file) {
        enqueueSnackbar('Please select excel file.', { variant: 'error' });
        return false;
      }
      if (!file.name.match(/\.(xls|xlsx)$/)) {
        enqueueSnackbar('Please select valid file.', { variant: 'error' });
        return false;
      }
      let targetName = target.name;
      let fileName = file.name;
      let fileExt = getFileExtension(fileName);
      let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
      const fileData = s3Key;
      Storage.put(s3Key, file, {
        contentType: file.type
      })
        .then(result => {
          setValues({ ...values, [targetName]: fileData, ImportFileName: file.name });
        })
        .catch(err => console.log(err));
    }
    else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };
  async function importVendors() {
    let apiName = "BaseAPI";
    let path = "/module";
    var body = {};
    body.masterType = "User";
    body.userData = userSession;
    body.ImportKey = values.ImportKey ? values.ImportKey : "";
    body.tenantId = userSession.TenantId
    body.currentUser = currentUserDetails;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "vendor",
        op: "importVendorData"
      }
    };
    console.log(myInit);
    API.post(apiName, path, myInit).then(async (response) => {
      if (response.success) {
        enqueueSnackbar("Profile Picture Updated successfully.. !! .", {
          variant: 'success',
        });
        handleClose();
      }
      else {
        enqueueSnackbar("Something went wrong. Try after sometime!!", {
          variant: 'error',
        });
      }
    }).catch(err => {
      enqueueSnackbar("Something went wrong. Try after sometime!!", {
        variant: 'error',
      });
      console.log(err);
    });
  }
  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  }
  const deleteFileFromS3 = async (element) => {
    if (values.ImportKey) await Storage.remove(values.ImportKey)
      .then(result => { console.log(result); })
      .catch(err => console.log(err));
    await setValues(prvValue => { return { ...prvValue, "ImportKey": '' } });
  }
  const handleClose = () => {
    manageModal(false);
    setOpen(false);
  };
  const downloadTemplate = async () => {
    const link = document.createElement('a');
    link.href = `/static/Itraff-Vendor-Import-Template.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const FileComponent = props => {
    const classes = useStyles();
    return (<Card className={classes.card1}>
      <div className={classes.details}>
        <CardActions>
          <Fab onClick={() => deleteFileFromS3(props)} style={{ color: "#000000", background: "none", boxShadow: "none", }} size="small" aria-label="delete picture" component="span">
            <CloseSharpIcon />
          </Fab>
        </CardActions>
      </div>
      <CardContent classes={{ root: classes.cardBottomPadding }}>
        <Box
          display="flex"
          justifyContent="flex-start"
        >
          <AttachFileIcon />
          <Typography variant="h6"> {props.FileName}</Typography>
        </Box>
      </CardContent>
    </Card>);
  };

  return (
    <div>
      <Dialog onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        scroll='body'
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        aria-describedby="scroll-dialog-description"
        open={open}>
        <DialogContent dividers>
          <form
            autoComplete='off'
            noValidate
            {...rest}>
            <Card >
              <CardHeader
                id="customized-dialog-title"
                subheader='Upload Excel file as per template'
                title='Vendor Import'
                action={<IconButton aria-label='close' onClick={handleClose}>
                  <CloseIcon />
                </IconButton>}
              />
              <CardContent scroll-dialog-description>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <Card {...rest}>
                      <CardContent>
                        {values.ImportKey ?
                          <FileComponent FileKey={values.ImportKey} FileName={values.ImportFileName} />
                          :
                          <>
                            <input name='ImportKey' onChange={(e) => onChange(e)} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className={classes.input} id="icon-button-file1" type="file" />
                            <label htmlFor="icon-button-file1">
                              <Grid container direction="row" spacing={3}>
                                <Grid item xs={2}>

                                  <Fab size="small" color="secondary" aria-label="upload picture" component="span">
                                    <AddIcon />
                                  </Fab>
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography variant="h6"> UPLOAD VENDOR DATA</Typography>
                                  <Typography variant="caption">Supported format- .xls .xlsx</Typography>
                                </Grid>
                              </Grid>
                            </label>
                          </>
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: '14px' }}
                    onClick={downloadTemplate}
                  >
                    Download Template
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      importVendors()
                    }}
                  >
                    UPLOAD
                  </Button>

                </Box>
              </CardActions>
            </Card>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

ImportVendor.prototype = {
  manageModal: PropTypes.func,
  importOpen: PropTypes.bool,
  userSession: PropTypes.object
};

export default ImportVendor;