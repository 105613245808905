import React, { useState,useEffect } from 'react'; 
import clsx from 'clsx';
import PropTypes from 'prop-types'; 
import PerfectScrollbar from 'react-perfect-scrollbar'; 
import { 
  Box,
  Card, 
  makeStyles
} from '@material-ui/core'; 
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
import MaterialTable from "material-table"; 
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));  

  const Results = ({ userSession, className, filterChange,queryChange,setRowSelected, ...rest }) => { 
  const classes = useStyles();   
  const [pageSize, setPageSize] = usePersistedState('pageSize', 15);     
  const tableRef = React.createRef(); 
   useEffect(() => { 
    if(tableRef.current) tableRef.current.onQueryChange({page: 0, Filter: filterChange})
   },[filterChange]);
   async function GetPagingData(query) { 
    queryChange(query);
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
    let body = {};  
    setPageSize(pageSize);  
    body = {
      type: "Vendor",
      UserID: userSession.UserName,
      tenantId:userSession.TenantId,
      filter:filterChange,
      pageSize: pageSize,
      pageNo: pageNo 
    };
    body.userData = userSession;
    return new Promise((resolve, reject) => {
      let apiName = "BaseAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: "vendor",
          op: "getVendorData"
        }
      };
      API.post(apiName, path, myInit).then(response => { 
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
        <MaterialTable
            tableRef={tableRef}
            columns={[
              {
                title: 'Vendor Name',
                field: 'VendorName',
                searchable: false,
                width:"5%"
              },
              {
                title: 'Email',
                field: 'Email',
                searchable: false ,
                width:"15%"
              },
              {
                title: 'Pan',
                field: 'Pan',
                searchable: false,
                width:"5%"
              },
              {
                title: 'Tan',
                field: 'Tan',
                searchable: false, 
                width:"5%"
              } 
            ]}
           
            data={async query =>
              new Promise((resolve, reject) => {
                GetPagingData(query).then(result => {
                  resolve({
                    data: result.data,
                    page: result.page,
                    totalCount: result.total
                  });
                });
              })
            }
            onSelectionChange={(rows) =>{   
             const final= rows.map(row => { return row.pk});
               setRowSelected(final);
              }}
            options={{
              searchFieldStyle: {
                display: 'none'
              },
              selection: true,
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              toolbar: false,
              pageSize: pageSize,
              pageSizeOptions: [5, 25, 50, 100, 200]
            }}           
          />
          </Box>
      </PerfectScrollbar> 
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  filterChange: PropTypes.string, 
  userSession:PropTypes.object,
  queryChange:PropTypes.func,
  setRowSelected:PropTypes.func
};

export default Results;
