import React, { useState,useRef,useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import Amplify, { Auth,API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import { useSnackbar } from 'notistack';

Amplify.configure(awsconfig);

const useStyles = makeStyles(({
  root: {},
  textdanger:{
    color: "red"
  }
}));

const Password = (props,{ className, ...rest }) => {
  console.log('props',props);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    oldpassword:'',
    password: '',
    confirm: '' ,
    error:''   
  });
  let textInputOld = useRef(null);
  let textInputNew = useRef(null);
  let textInputConfirm = useRef(null);

  const [errorPwd, setErrorPwd] = useState("");
  useEffect(() => { 
    console.log('userDetails 13',props.userDetails)
  },[]);
   const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    
  };
  const validate = (event) => { 
    let isValid = true;
    setErrorPwd('');  
    if (typeof values["password"] == "undefined"){
      isValid = false;
      setErrorPwd("Old Password is Empty.");
      return isValid;
    }  
    if (typeof values["password"] !== "undefined" && typeof values["confirm"] !== "undefined") {
      
      if (values["password"] != values["confirm"]) {
        isValid = false;
        setErrorPwd("Passwords don't match.");
        }
   }   
  return isValid;
  }
  async function handleSubmit()  {
    if(validate()){       
    let oldPassword= values["oldpassword"];
    let password = values["password"];
    const currentUser = await Auth.currentAuthenticatedUser();
     Auth.changePassword(
        currentUser,
        oldPassword,
        password
      )
        .then(response => { 
          console.log("response" + JSON.stringify(response));
          setIsLoading(false);
          enqueueSnackbar("Password successfully Updated.. !! .",
          { 
            variant: 'success',
          });  
          textInputOld.current.value = "";
          textInputNew.current.value = "";
          textInputConfirm.current.value = "";
        })
        .catch(err => {
          console.log(err); 
          setIsLoading(false);
          /*enqueueSnackbar(err.message,{ 
            variant: 'error',
          }); */ 
          enqueueSnackbar("The password that you've entered is incorrect",{ 
            variant: 'error',
          }); 
               
        });
    }
    } 
   return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent>
        <TextField
               fullWidth
               label="Old Password"
               margin="normal"
               inputRef={textInputOld}
               name="oldpassword"
               onChange={handleChange}
               type="password"
               value={values.oldpassword}
               variant="outlined"
                />
          <TextField
            fullWidth
            label="New Password"
            margin="normal"
            name="password"
            inputRef={textInputNew}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            inputRef={textInputConfirm}
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
           <div className={classes.textdanger}>{errorPwd}</div>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => { 
              handleSubmit()           
            
            }}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
