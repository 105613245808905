import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout'; 
import MainLayout from 'src/layouts/MainLayout';
import PlainLayout from 'src/layouts/PlainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView'; 
import RegisterView from 'src/views/auth/RegisterView';
import VerifyView from 'src/views/auth/VerifyView';
import SettingsView from 'src/views/settings/SettingsView'; 
import CustomerInvoiceView from 'src/views/customerInvoice/CustomerInvoiceView';  
import AffidavitForm from 'src/views/affidavit/AffidavitForm'
import ReturnFilling from 'src/views/affidavit/ReturnFilling';
import Thankyou from 'src/views/affidavit/ThankYou'
 export const fakeAuth = {
  signedIn: false
};
 
const routes =(isAuthenticated,userHasAuthenticated,userSession,setuserSession,props)=>{ 
  console.log("userSession.AccountType",userSession.AccountType);
  return [{
    path: 'app',
    element: isAuthenticated?<DashboardLayout isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated} userSession={userSession} setuserSession={setuserSession} props={props} />:<Navigate to="/login" />,
    children: [
      { path: 'account', element: <AccountView userSession={userSession} /> },
      { path: 'vendor', element: <CustomerListView userSession={userSession} /> },
      { path: 'dashboard', element: <DashboardView userSession={userSession}/> },
      { path: 'customerInvoice', element: <CustomerInvoiceView userSession={userSession} /> },   
      { path: 'affidavit', element: <ReturnFilling userSession={userSession} /> },           
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path:':companyName',
    element: <PlainLayout/>,
    children:[
      { path: 'itraffidavit', element: <AffidavitForm {...props} /> },
      { path: 'thankyou', element: <Thankyou /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout   />,
    children: [
      { path: 'login', element: <LoginView isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated} userSession={userSession} setuserSession={setuserSession} props={props}/> },
      { path: 'register', element: <RegisterView userSession={userSession}/> },
      { path: 'verify', element: <VerifyView userSession={userSession}/> },
      { path: '404', element: <NotFoundView userSession={userSession}/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> }, 
      { path: '*', element: <Navigate to="/404" /> }
    ] 
  }
]};

export default routes;
