import React, {useState, useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page'; 
import  LatestAffidavit from "./LatestAffidavit"; 
import InvoiceProcessed from './InvoiceProcessed';
import ProcessFailed from './ProcessFailed';
import InvoiceValidated from './InvoiceValidated';
import Spinner from 'src/common/Spinner';
import InvoiceRejected from './InvoiceRejected';
import ProfileSetup from './ProfileSetup'; 
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },paper: {
    'margin-bottom': '10px'

  }  
}));

const Dashboard = (props) => {
  const classes = useStyles(); 
  const [isLoading, setIsLoading] = useState(false); 
  const [currentUserDetails, setCurrentUserDetails] = usePersistedState('currentUserDetails',{});
  const [matrix, setMatrix] = usePersistedState('matrix',{
    "Downloaded":0,
    "Rejected":0,
    "Validation Failed":0,
    "Validated":0,
    "Monthly Count":0,
    "Processed":0
  }); 
  const [profileOpen, setProfileOpen] = React.useState(false);
  useEffect(() => { 
    dashboardMatrix();
    getUser();
  }, []);
  const dashboardMatrix = async () => {
    setIsLoading(true);
    try {
      let apiName = "BaseAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, tenantId: props.userSession.TenantId },
        queryStringParameters: {
          module: "invoice",
          op: "getDashboardMatix"
        }
      };
      var response = await API.post(apiName, path, myInit);
      await setMatrix(response.data ? response.data : {
        "Downloaded": 0,
        "Rejected": 0,
        "Validation Failed": 0,
        "Validated": 0,
        "Monthly Count": 0,
        "Processed": 0
      });
      await setIsLoading(false);
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }
   const getUser = async () => {
     setIsLoading(true);
     try {
       let apiName = 'BaseAPI';
       let path = '/module';
       let myInit = {
         headers: { 'Content-Type': 'application/json' },
         body: {
           masterType: 'User',
           UserID: props.userSession.UserName,
           tenantId: props.userSession.TenantId
         },
         queryStringParameters: {
           module: 'user',
           op: 'getUserProfile'
         }
       };
       var response = await API.post(apiName, path, myInit);
       await setCurrentUserDetails(response.data); 
       await setProfileOpen(response.data.IsFirstLogin);
       await setIsLoading(false);  
     } catch (error) {
       console.log('set login time error', error);
     }
   }; 
   const manageModal=(val)=>{
    setProfileOpen(val)
   }
  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Spinner left={70} top={0} className={classes.fabSpinner}  isLoading={isLoading} />
        <ProfileSetup userSession={props.userSession} profile={currentUserDetails} profileOpen={profileOpen} manageModal={manageModal} />
        <Grid
          container
          spacing={3}
        > 
     {/*      <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceProcessed matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ProcessFailed matrix={matrix} />
          </Grid> 
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceRejected matrix={matrix} />
          </Grid> 
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceValidated matrix={matrix} />
          </Grid> 
           */}
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >    
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
            >          
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <LatestAffidavit userSession={props.userSession} {...props} />
              </Grid>
            </Grid>
          </Grid> 
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
