import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {Folder as FolderIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  CheckBox as CheckBoxIcon
}  from '@material-ui/icons';
   
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  Modal,
  TextField
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Amplify, {API } from 'aws-amplify';
import awsconfig from 'src/aws-exports'; 
import { useSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
Amplify.configure(awsconfig);
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles =makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));
 

const BusinessPlaces = (props,{className, ...rest}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const [businessPlaces, setbusinessPlaces] = useState([]);
  const GSTINRegExp   = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  const [openModel, setOpenModel] = useState(false);
  const [isExitsGSTIN, setExitsGSTIN] = useState();
  const [modalStyle] = useState(getModalStyle);
  const [row,setRow] = useState({});
  const [updateBusinessPlc,setupdateBusinessPlc] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState("");

  

  const handleOpen = () => {
    setOpenModel(true);
  };

  const handleModelClose = () => {
    setOpenModel(false);
  };
  async function CheckingGSTIN(value) {       
    
    console.log("Get GST No");
    let apiName = "BaseAPI";
    let path = '/store/AvailableGSTIN';
    var body = {}; 
    body.gstin = value;
    body.tenantId=props.userSession.TenantId 
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body
    };
    console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      console.log("Responce Data", response);
      setExitsGSTIN(response.value);   
       
      
    }).catch(err => {
      console.log(err);      
      
    });
  
  
  }
  const [values, setUserDetails] = useState({
    FirstName:"",
    LastName:"",
    Email:"",
    PhoneNumber:"",
    GSTIN:"",
    CompanyName:"",
    BusinessPlaces:""
  }); 
  const [open, setOpen] = useState(false);  
  useEffect(() => {
    setUserDetails(props.userDetails);
    setbusinessPlaces([]);
    setbusinessPlaces(businessPlaces => [...businessPlaces, props.userDetails.BusinessPlaces]); 
    
    console.log('businessPlaces :'+businessPlaces)
    if(props.childParameters==1){
      handleSaveClick();
    }

  }, [props]); 
  const handleClick = (row,action) => {  
    handleClickOpen(row,action); 
    setAction(action)
    
  }
  const handleClickOpen = (row,action) => {  
    setOpen(true);   
    const BusPlaces =[];
    const BplacesArray=businessPlaces;   
     if(action=='update'){  
      row.status="inactive";
      setRow(row);
      var jsonData=JSON.stringify(row);    
      BplacesArray.map((gstValue) => (
        gstValue.filter(gt => gt != jsonData).map((gst) => (          
          BusPlaces.push(gst)
        ))));
        setupdateBusinessPlc(BusPlaces);    
       
    }else if(action=='activate'){
      row.status="active";
      setRow(row);
      var jsonData=JSON.stringify(row);    
      BplacesArray.map((gstValue) => (
        gstValue.filter(gt => gt != jsonData).map((gst) => (          
          BusPlaces.push(gst)
        ))));
        setupdateBusinessPlc(BusPlaces);

    } else if(action=='add'){
      BplacesArray.map((gstValue) => (
        gstValue.filter(gt => gt != jsonData).map((gst) => (          
          BusPlaces.push(gst)
        ))));
      const AddBPlaces ={"gstin":row.gstin,"primary":"no","status":"active"};
      BusPlaces.push(AddBPlaces); 
      setupdateBusinessPlc(BusPlaces);        
      handleModelClose()
    }
  };

  const handleClose = () => {     
    row.status="active";
    setRow(row);
    setOpen(false);
  };
  const handleSaveClick = () => {  
    //updatepropertyDB();   
    setOpen(false);
    setIsLoading(false);
  };
  const style = {
    float:'right'     
  };
  
  const body = (
    <div style={modalStyle} className={classes.paper}>
      
     <Formik
             initialValues={{               
               gstin:''              
             }}
             validationSchema={
               Yup.object().shape({                 
                 gstin: Yup.string().required("GSTIN is required").matches(GSTINRegExp, 'GSTIN is not valid').test('Unique GSTIN','GSTIN already in use', 
                 function(value){return new Promise((resolve, reject) => {
                    CheckingGSTIN(value);                   
                   isExitsGSTIN=='NotAvailable'?resolve(false):resolve(true)  
                 })}
             ) 
               })
             }
             onSubmit={async (values) => {
               await handleClick(values,'add');                
              }} 
           >
             {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values
             }) => (
               <form onSubmit={handleSubmit}>
                 <Box mb={3}>
                   <Typography
                     color="textPrimary"
                     variant="h2"
                   >
                     Add GSTIN <Button color="primary" onClick={(event, row) => { handleModelClose()}} style={style} ><CloseIcon></CloseIcon></Button>
                   </Typography> 
                   <Typography
                     color="textSecondary"
                     gutterBottom
                     variant="body2"
                   >
                     New GSTIN
                   </Typography>
                 </Box>
                  
                  
                 <TextField
                   error={Boolean(touched.gstin && errors.gstin)}
                   fullWidth
                   helperText={touched.gstin && errors.gstin}
                   label="GSTIN"
                   margin="normal"
                   name="gstin"
                   onBlur={handleBlur}
                   onChange={handleChange}
                   value={values.gstin}
                   variant="outlined"
                 />
                  <Box my={2}>
                   <Button
                     color="primary"
                     disabled={isSubmitting}
                     fullWidth
                     size="large"
                     type="submit"
                     variant="contained"
                   >
                      Save
                   </Button>
                 </Box>
                 
                  
               </form>
             )}
           </Formik>
           
           </div>
   );
   return (

    <div className={classes.root}> 
    <Card>  
    <div>
       <Modal
        open={openModel}
        onClose={handleModelClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >        
        {body}
      </Modal>       
    </div>
    <CardHeader
          subheader="Update GSTIN"
          title="Business Places"
          action={
            <Tooltip title="Add"> 
            <Button
variant="outlined"
className="errorIcon"
style={{color:"blue"}}
onClick={(event, row) => {  
  handleOpen()
}}
 >
Add
</Button></Tooltip>
          }
        />
    <Divider/>  
    <Grid container spacing={3}>         
 
      <Grid item xs={12} md={12}>
        
        <div className={classes.demo}>
          <List dense={dense}>
            {
            businessPlaces!=''?
            values?
            businessPlaces.map((gstValue) => (
              gstValue.map((gst) => (
              <ListItem>                
              <ListItemText
                 primary={gst.gstin}
                secondary={secondary ? 'Secondary text' : null}
              />
              <ListItemSecondaryAction>
              {(gst.primary=='yes')?  
              <Button color="primary">                   
              </Button>:
              (gst.status=='active')?
              <Tooltip title="Disable"> 
              <Button color="primary" onClick={(event, row) => {
                handleClick(gst,'update')               
              }}>
              <CheckBoxIcon />
              </Button>
               </Tooltip>
              :
              <Tooltip title="Enable"> 
              <Button color="primary" onClick={(event, row) => {
                handleClick(gst,'activate')               
              }}>
              <CloseIcon />
              </Button>
              </Tooltip>
              }
              </ListItemSecondaryAction>
            </ListItem>
            ))
            ))
             
            :<ListItem></ListItem>:
            <ListItem></ListItem>
            }
           </List>
        </div>
      </Grid>
    </Grid>
    </Card>
    <div>       
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Notification"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">             
              Do you want to {action} this GSTIN                  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button /*onClick={handleSaveClick}*/
           onClick={props.handleGSTIN.bind(null,updateBusinessPlc)}
          color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>   
  </div>
  );
};

BusinessPlaces.propTypes = {
  className: PropTypes.string,
  handleGSTIN:PropTypes.func
};

export default BusinessPlaces;
