import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar'; 
import { useSnackbar } from 'notistack'; 
import { API } from "aws-amplify";

let apiName = "BaseAPI";
let path = "/module";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [filterChange,setFilterChange]=useState('');
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelected,setRowSelected]=useState([]);
  const [toolBarAction,setToolBarAction]=useState(false);
  React.useEffect(() => { 
      console.log('row from index page',rowSelected);
      if(rowSelected.length>0) setToolBarAction(true);
      else setToolBarAction(false);
  },[rowSelected]);
  const handleSearch=async (e)=> {
    let target=e.target;
    setFilterChange(target.value);   
  }
  const queryChange=async (e)=>{
    setPageSize(e.pageSize)
  }
  const exportOnClick = async () => {
    setIsLoading(true);   
    var body = {};
    body.type = "Vendor";
    body.userData = props.userSession; 
    body.tenantId = props.userSession.TenantId;
    body.PageSize=pageSize;
    body.filter=filterChange;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "vendor",
        op: "exportVendorData"
      }
    };
    API.post(apiName, path, myInit).then(async (response) => {
      console.log("UPDATE USER PROPERTY", response);
      if (response.success) {
        const link = document.createElement('a');
        link.href = response.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false);
      }
      else {
        enqueueSnackbar("Something went wrong. Try after sometime!!", {
          variant: 'error',
        });
        setIsLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    });
  }
  const sendAffidavitForm=async ()=>{
    setIsLoading(true); 
    if(rowSelected.length===0) {
      enqueueSnackbar("Select rows before submitting !!", {
        variant: 'error',
      });
      return false;
    }
    var body = {};
    body.type = "Vendor";
    body.userData = props.userSession; 
    body.tenantId = props.userSession.TenantId; 
    body.vendors=rowSelected;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "vendor",
        op: "sendAffidavitForm"
      }
    };
    API.post(apiName, path, myInit).then(async (response) => {
      console.log("UPDATE USER PROPERTY", response);
      if (response.success) {
        enqueueSnackbar(response.message, {
          variant: 'success',
        });
        setIsLoading(false);
      }
      else {
        enqueueSnackbar(response.message, {
          variant: 'error',
        });
        setIsLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    });
  }
  return (
    <Page
      className={classes.root}
      title="Vendors"
    >
      <Container maxWidth={false}>
        <Toolbar toolBarAction={toolBarAction} isLoading={isLoading} sendAffidavitForm={sendAffidavitForm} handleSearch={handleSearch} exportOnClick={exportOnClick} userSession={props.userSession} />        
        <Box mt={3}>
          <Results filterChange={filterChange} queryChange={queryChange} setRowSelected={setRowSelected} userSession={props.userSession}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;

 