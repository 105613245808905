import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Amplify, { Auth, API } from 'aws-amplify';
import config from 'src/aws-exports';
import { useSnackbar } from 'notistack';
import Spinner from 'src/common/Spinner';

Amplify.configure(config);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const cacheTest =  (asyncValidate) => {
  let _valid = false;
  let _value = ''; 
  return async (value) => {
    console.log(value,_value);
    if (value !== _value) {
      const response = await asyncValidate(value);
      console.log(response);
      _value = value;
      _valid = response;
      return response;
    }
    return _valid;
  };
};
const RegisterView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar(); 
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const PANRegExp = /[A-Z]{5}[0-9]{4}[A-Z]{1}/
  const TANRegExp = /^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$/
  const [isLoading, setIsLoading] = useState(false);
  const companyCodeUniqueTest = React.useRef(cacheTest(CheckingCompanyCode));

  async function signUp(e) {
    var username = e.email;
    var password = e.password;
    var attrVal = {
      email: e.email,
      name: e.firstName + ' ' + e.lastName,
      'custom:firstname': e.firstName,
      'custom:lastname': e.lastName,
      'custom:tenantid': e.email,
      'custom:accounttype': 'MASTER',
      'phone_number': '+91' + e.phone,
    };
    Auth.signUp({
      username,
      password,
      attributes: attrVal
    })
      .then(response => {
        console.log('response' + JSON.stringify(response));
        if (response.UserSub !== '') {
          console.log('Successful userSub' + response.UserSub);
          UpdateUser(response, e);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  };
  async function CheckingCompanyCode(value) {
    let apiName = "BaseAPI";
    let path = '/store/checkCompanyCodeExist';
    var body = {};
    body.companyCode = value;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body
    }; 
    var res= await API.post(apiName, path, myInit);
    console.log("Response Data", res); 
    return res.value==='NotAvailable'? false:true; 
  }
  async function UpdateUser(res, e) { 
    console.log('UPDATE User TO DB');
    let apiName = 'BaseAPI';
    let path = '/store/updateUserProperty';
    var body = {};
    body.pk = res.userSub;
    body.email = e.email;
    body.name = e.firstName + ' ' + e.lastName;
    body['custom:firstname'] = e.firstName;
    body['custom:lastname'] = e.lastName;
    body['custom:accounttype'] = 'ADMIN';
    body['custom:tenantid'] = e.email;
    body['PhoneNumber'] = e.phone;
    body['pan'] = e.pan;
    body['tan'] = e.pan; 
    body['companyName'] = e.company;
    body['companyCode']=e.companyCode
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: body
    };
    console.log(myInit);
    API.post(apiName, path, myInit).then(response => {
      setIsLoading(false);
      enqueueSnackbar('User ' + res.user.username + ' Registered successfully.. !!  Please confirm your email id before login.',
        {
          variant: 'success',
        });
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      enqueueSnackbar(err,
        {
          variant: 'error',
        });
    });
  }  
  return (
    <Page
      className={classes.root}
      title='Register'
    >
      <Spinner left={70} top={0} isLoading={isLoading} />
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'
      >
        <Container maxWidth='sm'>
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              phone: '',
              pan: '',
              tan: '',
              company: '',
              companyCode:'',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                firstName: Yup.string().max(255).required('First name is required'),
                lastName: Yup.string().max(255).required('Last name is required'),
                password: Yup.string().max(255).required('password is required'),
                phone: Yup.string().required('Phone number required').matches(phoneRegExp, 'Phone number is not valid')
                  .min(10, 'to short')
                  .max(10, 'to long'),
                pan: Yup.string().required('PAN is required').matches(PANRegExp, 'PAN is not as per specified format'),
                tan: Yup.string().required('TAN is required').matches(TANRegExp, 'TAN is not as per specified format'),
                company: Yup.string().max(255).required('Company name is required'),
                companyCode:Yup.string().max(15).required('Company Code is required').test('Unique Company Code','Company code already in use',companyCodeUniqueTest.current),
                policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={async (values, { resetForm }) => {
              await signUp(values);
              resetForm({ values: '' })
              console.log('(JSON.stringify(values, null, 2)', (JSON.stringify(values, null, 2)));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color='textPrimary'
                    variant='h2'
                  >
                    Create new account
                  </Typography>
                  <Typography
                    color='textSecondary'
                    gutterBottom
                    variant='body2'
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label='First name'
                  margin='normal'
                  name='firstName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant='outlined'
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label='Last name'
                  margin='normal'
                  name='lastName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant='outlined'
                />
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label='Phone Number'
                  margin='normal'
                  name='phone'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='phone'
                  value={values.phone}
                  variant='outlined'
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label='Email Address'
                  margin='normal'
                  name='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='email'
                  value={values.email}
                  variant='outlined'
                />
                <TextField
                  error={Boolean(touched.pan && errors.pan)}
                  fullWidth
                  helperText={touched.pan && errors.pan}
                  label='PAN'
                  margin='normal'
                  name='pan'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pan}
                  variant='outlined'
                />
                <TextField
                  error={Boolean(touched.tan && errors.tan)}
                  fullWidth
                  helperText={touched.tan && errors.tan}
                  label='TAN'
                  margin='normal'
                  name='tan'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.tan}
                  variant='outlined'
                />
                <TextField
                  error={Boolean(touched.company && errors.company)}
                  fullWidth
                  helperText={touched.company && errors.company}
                  label='Company name'
                  margin='normal'
                  name='company'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company}
                  variant='outlined'
                />
                <TextField
                  error={Boolean(touched.companyCode && errors.companyCode)}
                  fullWidth
                  helperText={touched.companyCode && errors.companyCode}
                  label='Company Code'
                  margin='normal'
                  name='companyCode'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyCode}
                  variant='outlined'
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label='Password'
                  margin='normal'
                  name='password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='password'
                  value={values.password}
                  variant='outlined'
                />
                <Box
                  alignItems='center'
                  display='flex'
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name='policy'
                    onChange={handleChange}
                  />
                  <Typography
                    color='textSecondary'
                    variant='body1'
                  >
                    I have read the
                    {' '}
                    <Link
                      color='primary'
                      component={RouterLink}
                      to='#'
                      underline='always'
                      variant='h6'
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color='textSecondary'
                  variant='body1'
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to='/login'
                    variant='h6'
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
