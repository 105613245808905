import React, { useState } from 'react';
import clsx from 'clsx';
import {
  withStyles,
  Dialog,
  IconButton, 
  Grid,
  TextField,
  Card,
  CardHeader,
  Divider,
  CardContent,
  makeStyles,
  Button,
  Fab,
  Box,
  Typography,
  CardMedia
} from '@material-ui/core';
import { API, Storage } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react'; 
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiCardActions from '@material-ui/core/CardActions';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon  from '@material-ui/icons/Add';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { useSnackbar } from 'notistack'; 
const defaultProps = { 
  style: { width: '18rem', height: '2rem' },
}; 
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  avatar: {
    height: 159,
    width: 159, 
    backgroundColor: '#bdbdbd24'
  },
  avatarBg: {
    backgroundColor: '#fff'
  }
  , input: {
    display: 'none',
  }, center: {
    'text-align': 'center'
  },
  borderRadious: {
    'border-radius': '5px !important'
  },
  card1: {
    display: "flex",
    alignItems:'center',
    marginRight: "auto", 
    maxHeight: "215px",
    maxWidth: "215px"
  },
  details: {
    display: "flex"
  },
  input: {
    display: 'none',
  },
}));
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const CardActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    alignItems:'flex-end',
    justifyContent:'flex-end',
    display:'flex'
  },
}))(MuiCardActions);
const ProfileSetupDialog = ({userSession, profile, profileOpen, manageModal, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [logoKey, setLogoKey] = React.useState('');
  const [values, setValues] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    Pan: '',
    Tan: '',
    CompanyName: '',
    CompanyLogo: ''
  });  
  React.useEffect(() => {
    setOpen(profileOpen);
  }, [profileOpen]);
  React.useEffect(() => {
   if(profile) {
     setValues(profile);
    }
  }, [profile]);
  const onChange = (e) => {
    if (e.target.name === 'CompanyLogo') {
      const target = e.target;
      const file = target.files[0];
      let targetName = target.name;
      var img = document.createElement('img');
      img.onload = function () {
        if (targetName === 'CompanyLogo' && this.width <= 500 && this.height <= 500) {
          if (values.CompanyLogo) {
            deleteFileFromS3(values.CompanyLogo)
          }
          let fileName = file.name;
          let fileExt = getFileExtension(fileName);
          let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
          const fileData = s3Key;
          Storage.put(s3Key, file, {
            contentType: file.type
          })
            .then(result => {
              setValues({ ...values, [targetName]: fileData });
            })
            .catch(err => console.log(err));
        }
        else {
          enqueueSnackbar('Image size should be lesser than 500X500', { variant: 'error' });
        }
      };
      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(target.files[0]);
    }
    else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };
  
async function setUserProfile() { 
  console.log("UPDATE PROPERTY TO DB");
  let apiName = "BaseAPI";
  let path = "/module";     
  var body = {}; 
  body.masterType = "User";
  body.userData = userSession;
  body.CompanyLogo =values.CompanyLogo?values.CompanyLogo:"";     
  body.tenantId=userSession.TenantId 
  let myInit = {
    headers: { "Content-Type": "application/json" },
    body: body,   
    queryStringParameters: {
      module: "user",
      op: "editUserProfile"
    }
  };
  console.log(myInit);

  API.post(apiName, path, myInit).then(async (response) => {
    console.log("UPDATE USER PROPERTY", response);  
     if(logoKey) await Storage.remove(logoKey)
    .then(result => { console.log(result); })
    .catch(err => console.log(err));   
    if(response.success)
    {
       
      enqueueSnackbar("Profile Picture Updated successfully.. !! .",{ 
        variant: 'success',
      });      
    }
    else
    {
      enqueueSnackbar("Something went wrong. Try after sometime!!",{ 
        variant: 'error',
      }); 
    }
  }).catch(err => {
    console.log(err);
     
    
  });
}
  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  }
  const deleteFileFromS3 = async (element) => { 
    await setValues(prvValue => { return { ...prvValue, "CompanyLogo": ''} });  
    await setLogoKey(values.CompanyLogo);
  }
  const handleClose = () => {
    manageModal(false);
    setOpen(false);
  };
  const ImageComponent = props => {
    const classes = useStyles();
    return (<Card className={classes.card1 }>
        <div className={classes.details}>
          <CardActions>
            <Fab onClick={() => deleteFileFromS3(props)} style={{ color: "#000000", background: "none", boxShadow: "none", }} size="small" aria-label="delete picture" component="span">
              <CloseSharpIcon />
            </Fab>  
          </CardActions>
        </div>
        <CardMedia>
          <S3Image theme={{ photoImg: { maxWidth: '159px' , maxHeight:  '159px', display: "flex", marginRight: "auto", padding: "14px" } }} level="public" imgKey={props.FileKey} />
        </CardMedia>
      </Card>);
  };
  
  return (
    <div>
      <Dialog onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        scroll='body'
        aria-labelledby='customized-dialog-title'
        aria-describedby="scroll-dialog-description"
        open={open}>
        <DialogContent dividers>
          <form
            autoComplete='off'
            noValidate
            {...rest}>
            <Card>
              <CardHeader
                 id="customized-dialog-title"
                subheader='The information can be edited'
                title='Profile'
                action={<IconButton aria-label='close' onClick={handleClose}>
                  <CloseIcon />
                </IconButton>}
              />
              <Divider />
              <CardContent scroll-dialog-description>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label='First name'
                      name='firstName'
                      onChange={onChange}
                      required
                      value={values.FirstName ? values.FirstName : ''}
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label='Last name'
                      name='lastName'
                      onChange={onChange}
                      required
                      value={values.LastName ? values.LastName : ''}
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label='Email Address'
                      name='email'
                      onChange={onChange}
                      required
                      value={values.Email ? values.Email : ''}
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label='Phone Number'
                      name='phone'
                      onChange={onChange}
                      type='number'
                      value={values.PhoneNumber ? values.PhoneNumber : ''}
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label='PAN'
                      name='pan'
                      onChange={onChange}
                      required
                      value={values.Pan ? values.Pan : ''}
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label='TAN'
                      name='tan'
                      onChange={onChange}
                      required
                      value={values.Tan ? values.Tan : ''}
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label='Company Name'
                      name='company'
                      onChange={onChange}
                      required
                      value={values.CompanyName ? values.CompanyName : ''}
                      variant='outlined'
                      disabled
                    />

                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <Card {...rest}>
                      <CardContent> 
                            {values.CompanyLogo ?
                            <ImageComponent FileKey={values.CompanyLogo} /> 
                              : 
                              <>
                              <input name='CompanyLogo' onChange={(e) => onChange(e)} accept="image/*" className={classes.input} id="icon-button-file1" type="file" />
                              <label htmlFor="icon-button-file1">
                              <Grid container direction="row" spacing={3}>
                              <Grid item xs={2}>
                               
                                  <Fab size="small" color="secondary" aria-label="upload picture" component="span">
                                    <AddIcon />
                                  </Fab> 
                              </Grid>
                              <Grid item xs={10}>
                                <Typography variant="h6"> UPLOAD COMPANY LOGO</Typography>
                                <Typography variant="caption">500x500 - png, jpeg, gif</Typography>
                              </Grid>
                            </Grid>
                            </label>
                            </>
                            }  
                      </CardContent> 
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions >
              <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        ><Button
        color="primary"
        variant="contained"
        onClick={() => { 
          setUserProfile()   
        }}
      >
        Update
      </Button>
         
          </Box>   
                      </CardActions>
            </Card>
          </form>
        </DialogContent> 
      </Dialog>
    </div>
  );
}

ProfileSetupDialog.prototype = { 
  manageModal:PropTypes.func,
  profile: PropTypes.object,
  profileOpen: PropTypes.bool,
  userSession: PropTypes.object  
};

export default ProfileSetupDialog;