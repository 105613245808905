import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Divider
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import ImportVendor from './ImportVendor';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  exportDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }, 
  exportSpinner: {
    color: "#909199",
    marginRight: "8px"
  },
  exportTextDisabled: {
    color: "#909199",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px"
  },  
  exportTextEnabled: {
    color: "#000000"
  },
  searchBox:{
    minWidth: '45%',
    float: 'left',
    display: 'flex',
    justifyContent: 'flex-start',    
    padding: '16px'
  },
  buttonBox:{
    minWidth: '45%',
    float: 'right',
    display: 'flex',
    justifyContent: 'flex-end', 
    padding: '24px 16px' 
  },
  toolbarContent:{
    padding:0
  }
})); 
const Toolbar = ({ className, handleSearch,userSession,exportOnClick,isLoading,sendAffidavitForm,toolBarAction, ...rest }) => {
 
  const classes = useStyles();
  const [importOpen, setImportOpen] = React.useState(false); 
  const manageImportModal=(val)=>{
    setImportOpen(val)
   }
   const importOnClick= async ()=>{
    manageImportModal(true);
  }
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    > 
      <ImportVendor userSession={userSession} importOpen={importOpen} manageModal={manageImportModal}/>
      <Box
        display="flex"
        justifyContent="flex-end"
      > 
        <Button onClick={importOnClick}>
          Import
        </Button> 
        <Divider orientation="vertical" flexItem className={classes.exportDivider} />
        <div className={isLoading ? classes.exportTextDisabled: classes.exportTextEnabled}>
        {isLoading && <CircularProgress  size={20} className={classes.exportSpinner}/>}         
        <Button disabled={isLoading}  className={classes.exportButton} onClick={exportOnClick}>
          Export
        </Button>
        </div>        
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent className={classes.toolbarContent}>
            <Box  className={classes.searchBox}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search vendor"
                variant="outlined"
                id="searchItem"       
                onBlur={handleSearch}
              />  
            </Box>
            <Box className={classes.buttonBox} >
            < Button color="primary" variant="contained"disabled={isLoading || !toolBarAction} className={classes.exportButton} onClick={sendAffidavitForm}>
                SEND AFFIDAVIT FORM
              </Button>
              </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleSearch:PropTypes.func,
  userSession:PropTypes.object,
  exportOnClick:PropTypes.func, 
  isLoading:PropTypes.bool,
  sendAffidavitForm:PropTypes.func,
  toolBarAction:PropTypes.bool
};

export default Toolbar;
