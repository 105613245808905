import React, { useState } from 'react';
import {
  Box,
  Container, 
  makeStyles
} from '@material-ui/core'; 
import Page from 'src/components/Page';    
import Toolbar from './Toolbar'; 
import { useSnackbar } from 'notistack'; 
import ListAffidavit from './ListAffidavit';
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  } 
}));
const ReturnFilling = (props) => {
  const classes = useStyles();  
  const { enqueueSnackbar } = useSnackbar();
  const [filterChange,setFilterChange]=useState('');
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const handleSearch=async (e)=> {
    let target=e.target;
    setFilterChange(target.value);   
  }
  const queryChange=async (e)=>{
    setPageSize(e.pageSize)
  }
  const exportOnClick = async () => {
    setIsLoading(true);
    let apiName = "BaseAPI";
    let path = "/module";
    var body = {};
    body.type = "Affidavit";
    body.userData = props.userSession; 
    body.tenantId = props.userSession.TenantId;
    body.PageSize=pageSize;
    body.filter=filterChange;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body,
      queryStringParameters: {
        module: "affidavit",
        op: "exportAffidavitData"
      }
    };
    API.post(apiName, path, myInit).then(async (response) => {
      console.log("UPDATE USER PROPERTY", response);
      if (response.success) {
        const link = document.createElement('a');
        link.href = response.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false);
      }
      else {
        enqueueSnackbar("Something went wrong. Try after sometime!!", {
          variant: 'error',
        });
        setIsLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    });
  }
  return (
    <Page
      className={classes.root}
      title="Customers"
    >
      <Container maxWidth={false}>
        <Toolbar isLoading={isLoading} handleSearch={handleSearch} exportOnClick={exportOnClick} userSession={props.userSession} />
        <Box mt={3}>
          <ListAffidavit filterChange={filterChange} queryChange={queryChange} userSession={props.userSession} />
        </Box>
      </Container>
    </Page>
  );
};
 
export default ReturnFilling;
