import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText, 
  TextField,
  Typography,
  makeStyles, 
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Paper,
  Divider,
  Card,
  CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
import Amplify, { API, Storage } from 'aws-amplify';
import config from 'src/aws-exports';
import { useSnackbar } from 'notistack';
import Spinner from 'src/common/Spinner';

Amplify.configure(config);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inputRoot: {
    '&$disabled': {
      backgroundColor:'#e9ecef'
    },
  },
  disabled: {},
  boxMargin:{
    margin:'29px'
  }
}));

const SurveyForm = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const PANRegExp = /[A-Z]{5}[0-9]{4}[A-Z]{1}/; 
  const [isLoading, setIsLoading] = useState(false); 
  const [proofFile,setProofFile]=useState({ 
    AckProof_20_21File:'',
    AckProof_19_20File:'' 
  })
  let { companyName } = useParams();
  console.log('companyName',companyName); 
  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  }
  const uploadToS3=async (file)=>{ 
    if(!file) return {}
      try {
        console.log('uploadToS3',file.name);
        let fileName = file.name;
        let fileExt = getFileExtension(fileName);
        let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;     
        let result =await Storage.put(s3Key, file, {contentType: file.type});
        console.log('s3put result',result);
        return { FileKey:s3Key,FileName:fileName};        
      } catch (error) {
        console.log('upload error',error);
      }
  }
  async function saveTdsTcsAffidavit(e) { 
    setIsLoading(true);
    console.log('save Tds Tcs Affidavit TO DB',e);
    let apiName = 'BaseAPI';
    let path = '/store/saveTdsTcsAffidavit'; 
    console.log('proofFile',proofFile); 
    e.AckProofs_19_20=await uploadToS3(proofFile.AckProof_19_20File);
    console.log('proof 3');
    e.AckProofs_20_21=await uploadToS3(proofFile.AckProof_20_21File);
    console.log('proof 4');
    e.CompanyCode=companyName;
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body:e
    };
    console.log(myInit);
    API.post(apiName, path, myInit).then(response => {
      console.log('proof 5');
      setIsLoading(false);
      if(response.Status)
      {
          navigate(`/${companyName}/thankyou`, { replace: true });           
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      enqueueSnackbar(err,
        {
          variant: 'error',
        });
    });
  }
  const onChange = (e) => {
    const target = e.target;
    const file = target.files[0];
    console.log('file',target.id,file);
    if (!file) {
      enqueueSnackbar('Please select a file.', { variant: 'error' });
      return false;
    }
    if (!file.name.match(/\.(png|jpg|PDF|pdf|xls|xlsx)$/)) {
      enqueueSnackbar('Please select valid file.', { variant: 'error' });
      return false;
    }
    if(target.id==='AckProof_20_21')
    {
      setProofFile({ ...proofFile, 'AckProof_20_21File':file });      
    }
    if(target.id==='AckProof_19_20')
    {
      setProofFile({ ...proofFile, 'AckProof_19_20File':file });      
    } 
     /*  let targetName = target.name;
      let fileName = file.name;
      let fileExt = getFileExtension(fileName);
      let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
      const fileData = s3Key;
      Storage.put(s3Key, file, {
        contentType: file.type
      })
        .then(result => {
          setValues({ ...values, [targetName]: fileData, ImportFileName: file.name });
        })
        .catch(err => console.log(err)); */
  };  
  return (
    <Page
      className={classes.root}
      title='Affidavit'
    >
      <Spinner left={70} top={0}  isLoading={isLoading} />
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'
      >
        <Container maxWidth='lg'>
          <Formik
            initialValues={{
              OrganizationName: '',
              Relations: '',
              HasPan: '',
              Pan: '',
              Turnover_20_21_Status: '',             
              Turnover_19_20_Status: '',
              Itr_19_20_Status: '',
              AckNo_19_20: '',
              AckProof_19_20: '',
              Itr_20_21_Status: '',
              AckNo_20_21: '',
              AckProof_20_21: '', 
              ContactName: '',
              ContactDesignation: '',
              ContactAddress: '',
              ContactCity: '',
              ContactState: '',
              ContactPin: '',
              ContactEmail: '',
              ContactPhone: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                OrganizationName: Yup.string().max(255).required("Field is required"),
                Relations: Yup.string().required("Field is required"),
                HasPan: Yup.string().required("Field is required"),
                Pan: Yup.string().when("HasPan", {
                  is: 'Yes',
                  then: Yup.string().required("Field is required").matches(PANRegExp, 'PAN is not as per specified format')
                }),  
                Turnover_19_20_Status: Yup.string().required("Field is required"),
                Itr_19_20_Status:Yup.string().required("Field is required"),
                AckNo_19_20: Yup.string().when("Itr_19_20_Status", {
                  is: 'Yes',
                  then: Yup.string().required("Field is required")
                }),
                AckProof_19_20: Yup.string().when("Itr_19_20_Status", {
                  is: 'Yes',
                  then: Yup.string()
                }),
                Turnover_20_21_Status: Yup.string().required("Field is required"),  
                Itr_20_21_Status: Yup.string().required("Field is required"),
                AckNo_20_21: Yup.string().when("Itr_20_21_Status", {
                  is: 'Yes',
                  then: Yup.string().required("Field is required")
                }),
                AckProof_20_21: Yup.string().when("Itr_20_21_Status", {
                  is: 'Yes',
                  then: Yup.string()
                }),
                ContactName: Yup.string().max(255).required("Field is required"),
                ContactDesignation: Yup.string().max(255).required("Field is required"),
                ContactAddress:Yup.string().max(255).required("Field is required"),
                ContactState:Yup.string().max(255).required("Field is required"),
                ContactCity:Yup.string().max(255).required("Field is required"),
                ContactPin: Yup.string().max(255).required("Field is required"),
                ContactEmail: Yup.string().email('Must be a valid email').max(255).required('Field is required'),
                ContactPhone: Yup.string().required('Field is required').matches(phoneRegExp, 'Phone number is not valid')
                  .min(10, 'to short')
                  .max(10, 'to long'),  
                policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={async (values, { resetForm }) => {
              await saveTdsTcsAffidavit(values);
              resetForm({ values: '' })
              console.log('(JSON.stringify(values, null, 2)', (JSON.stringify(values, null, 2)));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box className={classes.boxMargin} mb={3}>
                  <Typography
                    color='textPrimary'
                    variant='h2'
                  >
                    TDS and TCS Questionnaire From ITRAFF
                  </Typography>
                  <Typography
                    color='textSecondary'
                    gutterBottom
                    variant='h5'
                  >
                    Please fill below details
                  </Typography>
                </Box>
                <Card style={{ marginTop: '16px' }} variant="outlined">
                  <CardContent>
                    <Typography style={{ paddingLeft: "8px" }} gutterBottom color='textPrimary' variant="h2" component="h2">
                      TDS/TCS Details
                    </Typography>
                    <Divider />
                    <Grid style={{ padding: "16px" }} container direction="row" alignItems="center" spacing={1}>
                      <Grid style={{ paddingLeft: '12px' }} item xs={12} sm={12} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          Enter your Organization name :
                        </Typography>
                      </Grid>
                      <Grid style={{ paddingRight: '12px' }} item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.OrganizationName && errors.OrganizationName)}
                          fullWidth
                          helperText={touched.OrganizationName && errors.OrganizationName}
                          margin='normal'
                          name='OrganizationName'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.OrganizationName}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid style={{ paddingLeft: '12px' }} item xs={12} sm={12} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          Are You Vendor or Customer :
                        </Typography>
                      </Grid>
                      <Grid style={{ paddingRight: '12px' }} item xs={12} sm={12} md={6} lg={6}>
                        <RadioGroup row 
                          aria-label="Relations" 
                          name="Relations" 
                          value={values.Relations} 
                          onChange={handleChange} 
                          onBlur={handleBlur} 
                          margin='normal'
                          >
                          <FormControlLabel value="Vendor" control={<Radio />} label="Vendor" />
                          <FormControlLabel value="Customer" control={<Radio />} label="Customer" />
                        </RadioGroup>
                        {Boolean(touched.Relations && errors.Relations) && (
                          <FormHelperText error>
                            {errors.Relations}
                          </FormHelperText>
                        )} 
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper style={{ padding: '16px' }} variant="outlined" elevation={0}>
                          <Grid container direction="row" alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography
                                color='textSecondary'
                                gutterBottom
                                variant='h5'
                              >
                                Do You have Valid PAN :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <RadioGroup row
                                aria-label="HasPan"
                                name="HasPan"
                                value={values.HasPan}
                                onChange={handleChange}
                                onBlur={handleBlur} 
                                margin='normal'
                              >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                              {Boolean(touched.HasPan && errors.HasPan) && (
                                <FormHelperText error>
                                  {errors.HasPan}
                                </FormHelperText>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography
                                color='textSecondary'
                                gutterBottom
                                variant='h5'
                              >
                                If Yes, Enter PAN :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <TextField
                                error={Boolean(touched.Pan && errors.Pan)}
                                fullWidth
                                helperText={touched.Pan && errors.Pan}
                                margin='normal'
                                name='Pan'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Pan}
                                variant='outlined'
                                InputProps={{
                                  classes:{
                                    root: classes.inputRoot,
                                    disabled: classes.disabled
                                  }
                                }}
                                disabled={values.HasPan==='No' || values.HasPan===''?true:false}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper style={{ padding: '16px' }} variant="outlined" elevation={0}>
                          <Grid container direction="row" alignItems="center"> 
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography
                                color='textSecondary'
                                gutterBottom
                                variant='h5'
                              >
                                Is your turnover in FY 2020-21 is more than INR 10 Crore :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <RadioGroup row aria-label="Turnover_20_21_Status" name="Turnover_20_21_Status" value={values.Turnover_20_21_Status} onChange={handleChange}
                                margin='normal'
                                onBlur={handleBlur}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                              {Boolean(touched.Turnover_20_21_Status && errors.Turnover_20_21_Status) && (
                                <FormHelperText error>
                                  {errors.Turnover_20_21_Status}
                                </FormHelperText>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography
                                color='textSecondary'
                                gutterBottom
                                variant='h5'
                              >
                                Have You Filed ITR for FY 2020-21 :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <RadioGroup row aria-label="Itr_20_21_Status" name="Itr_20_21_Status" value={values.Itr_20_21_Status} onChange={handleChange}
                                margin='normal'
                                onBlur={handleBlur}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                              {Boolean(touched.Itr_20_21_Status && errors.Itr_20_21_Status) && (
                                <FormHelperText error>
                                  {errors.Itr_20_21_Status}
                                </FormHelperText>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography
                                color='textSecondary'
                                gutterBottom
                                variant='h5'
                              >
                                If Filed, Please mention ack number , attach proof :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                  <TextField
                                    error={Boolean(touched.AckNo_20_21 && errors.AckNo_20_21)}
                                    fullWidth
                                    helperText={touched.AckNo_20_21 && errors.AckNo_20_21}
                                    margin='normal'
                                    name='AckNo_20_21'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.AckNo_20_21}
                                    variant='outlined'
                                    InputProps={{
                                      classes:{
                                        root: classes.inputRoot,
                                        disabled: classes.disabled
                                      }
                                    }}
                                    disabled={values.Itr_20_21_Status==='No' || values.Itr_20_21_Status===''?true:false}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                  <TextField
                                    className={classes.margin}
                                    type="file"
                                    fullWidth
                                    variant='outlined'
                                    error={touched.AckProof_20_21 && Boolean(errors.AckProof_20_21)}
                                    helperText={touched.AckProof_20_21 ? errors.AckProof_20_21 : ''}
                                    onChange={onChange}
                                    onBlur={handleBlur}
                                    InputProps={{ className: classes.fileUpload }}
                                    inputProps={{ id: "AckProof_20_21" }}
                                    InputProps={{
                                      classes:{
                                        root: classes.inputRoot,
                                        disabled: classes.disabled
                                      }
                                    }}
                                    disabled={values.Itr_20_21_Status==='No' || values.Itr_20_21_Status===''?true:false}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper style={{ padding: '16px' }} variant="outlined" elevation={0}>
                          <Grid container direction="row" alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography
                                color='textSecondary'
                                gutterBottom
                                variant='h5'
                              >
                                Is your turnover in FY 2019-20 is more than INR 10 Crore :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <RadioGroup row aria-label="Turnover_19_20_Status" name="Turnover_19_20_Status" value={values.Turnover_19_20_Status} onChange={handleChange}
                                margin='normal'
                                onBlur={handleBlur}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                              {Boolean(touched.Turnover_19_20_Status && errors.Turnover_19_20_Status) && (
                                <FormHelperText error>
                                  {errors.Turnover_19_20_Status}
                                </FormHelperText>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography
                                color='textSecondary'
                                gutterBottom
                                variant='h5'
                              >
                                Have You Filed ITR for FY 2019-20 :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <RadioGroup row aria-label="Itr_19_20_Status" name="Itr_19_20_Status" value={values.Itr_19_20_Status} onChange={handleChange}
                                margin='normal'
                                onBlur={handleBlur}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                              {Boolean(touched.Itr_19_20_Status && errors.Itr_19_20_Status) && (
                                <FormHelperText error>
                                  {errors.Itr_19_20_Status}
                                </FormHelperText>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Typography
                                color='textSecondary'
                                gutterBottom
                                variant='h5'
                              >
                                If Filed, Please mention ack number , attach proof :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                  <TextField
                                    error={Boolean(touched.AckNo_19_20 && errors.AckNo_19_20)}
                                    fullWidth
                                    helperText={touched.AckNo_19_20 && errors.AckNo_19_20}
                                    margin='normal'
                                    name='AckNo_19_20'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.AckNo_19_20}
                                    variant='outlined'
                                    InputProps={{
                                      classes:{
                                        root: classes.inputRoot,
                                        disabled: classes.disabled
                                      }
                                    }}
                                    disabled={values.Itr_19_20_Status==='No' || values.Itr_19_20_Status===''?true:false}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                  <TextField
                                    className={classes.margin}
                                    type="file"
                                    fullWidth
                                    variant='outlined'
                                    error={touched.AckProof_19_20 && Boolean(errors.AckProof_19_20)}
                                    helperText={touched.AckProof_19_20 ? errors.AckProof_19_20 : ''}
                                    onChange={onChange}
                                    onBlur={handleBlur}
                                    InputProps={{ className: classes.fileUpload }}
                                    inputProps={{ id: "AckProof_19_20" }}
                                    InputProps={{
                                      classes:{
                                        root: classes.inputRoot,
                                        disabled: classes.disabled
                                      }
                                    }}
                                    disabled={values.Itr_19_20_Status==='No' || values.Itr_19_20_Status===''?true:false}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid> 
                    </Grid>
                  </CardContent>
                </Card>

                <Card style={{ marginTop: '16px' }} variant="outlined">
                  <CardContent>
                    <Typography style={{ paddingLeft: "8px" }} gutterBottom color='textPrimary' variant="h2" component="h2">
                      Contact Details
                    </Typography>
                    <Divider/>
                    <Grid style={{ padding: "16px" }} container direction="row" alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          Name of the person filling the form :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.ContactName && errors.ContactName)}
                          fullWidth
                          helperText={touched.ContactName && errors.ContactName}
                          margin='normal'
                          name='ContactName'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ContactName}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          Designation :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.ContactDesignation && errors.ContactDesignation)}
                          fullWidth
                          helperText={touched.ContactDesignation && errors.ContactDesignation}
                          margin='normal'
                          name='ContactDesignation'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ContactDesignation}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          Address :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.ContactAddress && errors.ContactAddress)}
                          fullWidth
                          multiline
                          helperText={touched.ContactAddress && errors.ContactAddress}
                          margin='normal'
                          name='ContactAddress'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ContactAddress}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          City :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.ContactCity && errors.ContactCity)}
                          fullWidth
                          helperText={touched.ContactCity && errors.ContactCity}
                          margin='normal'
                          name='ContactCity'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ContactCity}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          State :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.ContactState && errors.ContactState)}
                          fullWidth
                          helperText={touched.ContactState && errors.ContactState}
                          margin='normal'
                          name='ContactState'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ContactState}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          Pin Code :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.ContactPin && errors.ContactPin)}
                          fullWidth
                          helperText={touched.ContactPin && errors.ContactPin}
                          margin='normal'
                          name='ContactPin'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ContactPin}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          Email Address :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.ContactEmail && errors.ContactEmail)}
                          fullWidth
                          helperText={touched.ContactEmail && errors.ContactEmail}
                          margin='normal'
                          name='ContactEmail'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ContactEmail}
                          variant='outlined'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='h5'
                        >
                          Phone Number :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                          error={Boolean(touched.ContactPhone && errors.ContactPhone)}
                          fullWidth
                          helperText={touched.ContactPhone && errors.ContactPhone}
                          margin='normal'
                          name='ContactPhone'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ContactPhone}
                          variant='outlined'
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Paper style={{ marginTop: '16px' }} variant="outlined" elevation={0}>
                  <Box style={{ padding: "24px" }}
                    alignItems='center'
                    display='flex'
                    ml={-1}
                  >
                    <Typography
                      color='textSecondary'
                      gutterBottom
                      variant='h5'
                    >
                      Any liability arising to ACG on account of false / incorrect declaration, the same shall be recovered along with interest from vendor/customer as the case may be.
                    </Typography>
                  </Box>
                  <Divider />
                  <Box style={{ padding: "24px" }}
                    alignItems='center'
                    display='flex'
                    ml={-1}
                  >
                    <Checkbox
                      checked={values.policy}
                      name='policy'
                      onChange={handleChange}
                    />
                    <Typography
                      color='textSecondary'
                      variant='body1'
                    >
                      Yes, I accept and hereby confirm that the above mentioned information furnished is true and to the best of my belief and knowledge..
                    </Typography>
                    {Boolean(touched.policy && errors.policy) && (
                      <FormHelperText error>
                        {errors.policy}
                      </FormHelperText>
                    )}
                  </Box>
                </Paper>
                <Box my={2}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                   SUBMIT
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default SurveyForm;
