import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    makeStyles,
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    TableContainer,
    Paper
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
import Spinner from 'src/common/Spinner';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/MoreVert';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ReactJson from 'react-json-view'
import CloseIcon from '@material-ui/icons/Close';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    },
    parentDisable: {
        position: "fixed",
        top: 0,
        left: 0,
        background: "#666",
        opacity: 0.8,
        zIndex: 998,
        height: "100%",
        width: "100%",
    },
    overlayBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: " white; background: #666666; opacity: .8;",
        zIndex: 1000
    }
}));

const LatestAffidavit = ({ userSession, className, ...rest }) => {
    const classes = useStyles();
    const [invoiceData, setInvoiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pageSize, setPageSize] = usePersistedState('pageSize', 25);
    const [invoiceFilter] = useState("");
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [invoiceJson, setInvoiceJson] = useState({});
    const [invoiceOpen, setInvoiceOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const navigate = useNavigate();
    console.log('userSession userSession',userSession );
    useEffect(() => {
        let isSubscribed = true;
        const userFetch = async () => {
            if (userSession && isSubscribed) {
                var res = await GetPagingData({
                    pageSize: 5,
                    page: 0
                });
                console.log("got invoice", res);
                await setInvoiceData(res.data);
            }
            return () => isSubscribed = false;
        }
        userFetch();

    }, []); 
async function GetPagingData(query) { 
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
    let body = {}, masterType = "", apiModule = "", op = "";
    setIsLoading(true);
    setPageSize(pageSize);
    body = {
      type: 'Affidavit',
      tenantId:userSession.TenantId,
      UserID: userSession.UserName, 
      userData: userSession,
      pageSize: pageSize,
      pageNo: pageNo
    };
    return new Promise((resolve, reject) => {
      let apiName = "BaseAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: "affidavit",
          op: "getAffidavit"
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
    const handleClose1 = (e) => {
        setInvoiceOpen(false);
    };
    async function viewJson() {
        var row = currentRow;

    }
    async function openMenu(event, row) {
        let anchorElement = event.currentTarget;
    }

    async function handleMenuClose() {
        await setMenuOpen(false);
    }
    async function downloadInvoice(type) {
        let body = {};
        let apiName = "BaseAPI";
        let path = "/module";
        let masterType = "Signed Invoice";
        body = {
            type: type,
            pk: currentRow.pk,
            UserID: userSession.UserName,
            tenantId: userSession.TenantId
        };
        let myInit = {
            headers: { "Content-Type": "application/json" },
            body: body,
            queryStringParameters: {
                module: "invoice",
                op: "downloadInvoice"
            }
        };
        await API.post(apiName, path, myInit).then(response => {
            if (response.success) {
                const link = document.createElement('a');
                link.href = response.link;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        }).catch(err => {
            console.log(err);
        });
    }
    return (

        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardHeader title="Invoice" />
            <Divider />
            <PerfectScrollbar>
                <Box minWidth={800}>
                    <div className={isLoading ? 'parentDisable' : ''} width="100%">
                        <div className='overlay-box'>
                            <Spinner left={70} top={0} isLoading={isLoading} />
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Vendor Name
                                    </TableCell>
                                    <TableCell>
                                        Pan
                                    </TableCell> 
                                    <TableCell>
                                        Return Status Latest (Yes/No)
                                    </TableCell>
                                    <TableCell>
                                        Return Status Previous (Yes/No)
                                    </TableCell>
                                    <TableCell>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceData && invoiceData.map((invoice) => (
                                    <TableRow
                                        hover
                                        key={invoice.pk}
                                    >
                                        <TableCell>
                                            {invoice.OrganizationName}
                                        </TableCell>
                                        <TableCell>
                                            {invoice.Pan}
                                        </TableCell>
                                        <TableCell>
                                            {invoice.Itr_20_21_Status}
                                        </TableCell>
                                        <TableCell>
                                            {invoice.Itr_19_20_Status}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={(event, row) => {
                                                    console.log(invoice);
                                                    openMenu(event, invoice);
                                                }}
                                                color="primary"
                                                endIcon={<MenuIcon />}
                                                size="large"
                                                variant="text"
                                            >
                                            </Button>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </PerfectScrollbar>
            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    onClick={() => {
                        navigate('/app/affidavit')
                    }}
                    color="primary"
                    endIcon={<ArrowRightIcon />}
                    size="small"
                    variant="text"
                >
                    View all
                </Button>
            </Box>
            <Menu
                id="simple-menu"
                keepMounted
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={viewJson}>View Invoice Json</MenuItem>
                <MenuItem onClick={() => downloadInvoice("json")}>
                    Download Invoice Json
                </MenuItem>
                <MenuItem onClick={() => downloadInvoice("pdf")}>
                    Download Invoice PDF
                </MenuItem>
            </Menu>
        </Card>
    );
};

LatestAffidavit.propTypes = {
    className: PropTypes.string,
    userSession:PropTypes.object
};

export default LatestAffidavit;
